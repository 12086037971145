
// 
// taskdetails.scss
//


.task-tags {
    .bootstrap-tagsinput {
        padding: 0px;
        border: none;
        background-color: transparent;
    }
}

.attached-files{
    .files-list{
        .file-box{
            vertical-align: middle;
        }
    }
}

.assign-team{
    a{
        margin-right: 7px;
    }
}

/* File Upload */
.fileupload {
    overflow: hidden;
    position: relative;
    input{
        &.upload {
            cursor: pointer;
            filter: alpha(opacity=0);
            font-size: 20px;
            margin: 0;
            opacity: 0;
            padding: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

.add-new-plus {
    height: 32px;
    text-align: center;
    width: 32px;
    display: inline-block;
    line-height: 32px;
    color: $gray-600;
    font-weight: 700;
    background-color: $gray-300;
    border-radius: 50%;
}

.bootstrap-tagsinput input {
    color: #6c757d;
}
.bootstrap-tagsinput input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: inherit;
}

.bootstrap-tagsinput .label-info {
    background-color: #71b6f9;
    display: inline-block;
    font-size: 13px;
    margin: 3px 1px;
    padding: 0 5px;
    border-radius: 3px;
    font-weight: 500;
}

.bootstrap-tagsinput .tag {
    margin-right: 2px;
    color: white;
}